import axios from 'axios';
import {store} from '../store';
import { loginSuccess, logoutSuccess, registerSuccess , buyCoin ,sellCoin } from '../store/actions';
import authHeader from './auth-header';
const API_URL = "https://api.coinplayland.com/auth/";
const API_URL2 = "https://api.coinplayland.com/user/";

class AuthService {
    async login(username, password) {
        try {
            const response = await axios.post(API_URL + "login", { username, password });
            console.log(response)
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
                store.dispatch(loginSuccess(response.data));
            }
            return response.data;
        } catch (error) {
            console.error("Login error: ", error);
            throw error;
        }
    }

    logout() {
        localStorage.removeItem("user");
        store.dispatch(logoutSuccess());
    }

    async register(username, email, password) {
        try {
            await axios.post(API_URL + "register", { username, email, password });
            store.dispatch(registerSuccess());
            // Optionally, you can auto-login the user after registration
        } catch (error) {
            console.error("Registration error: ", error);
            throw error;
        }
    }

    getCurrentUser() {
        const state = store.getState();
        return state.user ? state.user.user : null;
    }


    async buy(username, coin, value,value2, price) {
      try {
          const response = await axios.post(API_URL2 + "buy", { username, coin, value,value2, price }, { headers: authHeader() });
          return response.data;
      } catch (error) {
          console.error("Buy error: ", error);
          throw error;
      }
  }

  async sell(username, coin, value,value2, price) {
      try {
        const response = await axios.post(API_URL2 + "sell", { username, coin, value,value2, price }, { headers: authHeader() });
        return response.data;
      } catch (error) {
          console.error("Sell error: ", error);
          throw error;
      }
  }

  async buyOrder(username, coin, value,value2, price) {
    try {
        const response = await axios.post(API_URL2 + "buyOrder", { username, coin, value,value2, price }, { headers: authHeader() });
        return response.data;
    } catch (error) {
        console.error("Buy error: ", error);
        throw error;
    }
}

async sellOrder(username, coin, value,value2, price) {
    try {
      const response = await axios.post(API_URL2 + "sellOrder", { username, coin, value,value2, price }, { headers: authHeader() });
      return response.data;
    } catch (error) {
        console.error("Sell error: ", error);
        throw error;
    }
}

  async getAllUsers() {
    try {
      const response = await axios.get(API_URL2 + "getAllUsers", { headers: authHeader() });
      return response.data;
    } catch (error) {
        console.error("Error: ", error);
        throw error;
    }
}

}

export default new AuthService();
