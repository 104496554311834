// GA
import ReactGA from 'react-ga4';

// utils
import {lazy, Suspense} from 'react';

// styles
import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';
import io from 'socket.io-client';

// contexts
import {SidebarProvider} from '@contexts/sidebarContext';
import {ThemeProvider} from 'styled-components';
import {ModalProvider} from '@contexts/modalContext';

// hooks
import {useThemeProvider} from '@contexts/themeContext';
import {useEffect, useRef} from 'react';
import {useWindowSize} from 'react-use';

// components
import {Route, Routes} from 'react-router-dom';
import Loader from '@components/Loader';
import Sidebar from '@components/Sidebar';
import BottomNav from '@components/BottomNav';
import Footer from '@widgets/Footer';
import { triggerCustomStorageChange } from '@contexts/UserDataProvider';
const Dashboard = lazy(() => import('@pages/Dashboard'));
const Trade = lazy(() => import('@pages/Trade'));
const Actions = lazy(() => import('@pages/Actions'));
const Wallet = lazy(() => import('@pages/Wallet'));
const NFT = lazy(() => import ('@pages/NFT'));
const Collections = lazy(() => import('@pages/Collections'));
const SignIn = lazy(() => import('@pages/SignIn'));
const SignUp = lazy(() => import('@pages/SignUp'));
const Verification = lazy(() => import('@pages/Verification'));
const SOCKET_SERVER_URL = "https://api.coinplayland.com";

const useUserUpdates = (userId) => {
    useEffect(() => {
      const socket = io(SOCKET_SERVER_URL, {
        path: '/socket', // Path specified in the server configuration
        transports: ['websocket', 'polling'],
        reconnectionDelayMax: 250,
        reconnectionAttempts: 50,
    });
  if (userId) {
        // Join the user-specific room
        socket.emit('join', userId);
        socket.on('allUsersDataChanged', (updatedUser) => {
          const allUser = updatedUser;
          const updatedCurrent = updatedUser.filter((element) => element._id === userId)[0]
          const user = JSON.parse(localStorage.getItem("user"));
          console.log("1",user,"3",userId)
          console.log("2",updatedCurrent)
          user.balance = updatedCurrent.balance
          user.transactions = updatedCurrent.transactions
          user.cryptocurrencies = updatedCurrent.cryptocurrencies
          user.weeklyProgress = updatedCurrent.weeklyProgress
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("allUsers", JSON.stringify(allUser));
          triggerCustomStorageChange();
        });
      }else{
        socket.on('allUsersDataChanged', (updatedUser) => {
          const allUser = updatedUser;
          localStorage.setItem("allUsers", JSON.stringify(allUser));
          triggerCustomStorageChange();
        });
      }
      return () => {
        socket.disconnect();
      };
    }, [userId]);
  };
const App = () => {
    const appRef = useRef(null);
    const {theme} = useThemeProvider();
    const {width} = useWindowSize();
    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
    }, []);
    const user = JSON.parse(localStorage.getItem('user'));
    // Pass the user ID to useUserUpdates to start listening for changes
    useUserUpdates(user ? user.id : null);
    const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
    gaKey && ReactGA.initialize(gaKey);
    return (
        <SidebarProvider>
            <ThemeProvider theme={{theme: theme}}>
                <ThemeStyles/>
                <ModalProvider>
                    <div ref={appRef}>
                        {width < 768 ? <BottomNav/> : <Sidebar/>}
                        <Suspense fallback={<Loader visible/>}>
                            <Routes>
                                <Route path="/" element={<Dashboard/>}/>
                                <Route path="/trade" element={<Trade/>}/>
                                <Route path="/actions" element={<Actions/>}/>
                                <Route path="/wallet" element={<Wallet/>}/>
                                <Route path="/nft" element={<NFT/>}/>
                                <Route path="/collections" element={<Collections/>}/>
                                <Route path="/sign-in" element={<SignIn/>}/>
                                <Route path="/sign-up" element={<SignUp/>}/>
                                <Route path="/verification" element={<Verification/>}/>
                            </Routes>
                        </Suspense>
                        <Footer/>
                    </div>
                </ModalProvider>
            </ThemeProvider>
        </SidebarProvider>
    );
}

export default App;
