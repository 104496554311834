import { createSlice } from '@reduxjs/toolkit';

const coinSlice = createSlice({
  name: 'coins',
  initialState: {
    currentCoin: null,
  },
  reducers: {
    setCurrentCoin: (state, action) => {
      state.currentCoin = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add your extra reducers here
  },
});

export const { setCurrentCoin } = coinSlice.actions;

export default coinSlice.reducer;
