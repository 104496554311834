import React from 'react';
import './styles.css';

const Footer = () => {
    const githubProfileLink = "https://github.com/G-DevendraBabu"; 
    const linkedinProfileLink = "https://www.linkedin.com/in/devendrababu-g-2a5911213"; 

    return (
        <div className='footer'>
            <h2 className='logo'>Coin Playland by Arif Dikkanat<span style={{ color: "#f0b90a" }}>.</span></h2>
            <div className='social-media-icons'>
            </div>
            <p className='copyright'>&copy; {new Date().getFullYear()} Crypto Simulator. All rights reserved.</p>
        </div>
    );
};

export default Footer;
