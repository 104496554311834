import axios from "axios";

export const get100Coins = () => {
  const coins = axios
    .get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false?x_cg_demo_api_key=CG-fW4Zc34z3mdBDiC2uM5AT9p8"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("ERROR>>>", error.message);
    });

  return coins;
};

const getBinanceData = async () => {
  const response = await axios.get("https://api.binance.com/api/v3/ticker/24hr");
  return response.data
    .filter((coin) => coin.symbol.endsWith("USDT"))
    .sort((a, b) => parseFloat(b.quoteVolume) - parseFloat(a.quoteVolume))
    .slice(0, 200);
};

const getCoinGeckoData = async () => {
  const response = await axios.get(
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=200&page=1&sparkline=false?x_cg_demo_api_key=CG-fW4Zc34z3mdBDiC2uM5AT9p8"
  )
  return response.data;
};

export const get200Coins = async () => {
  try {
    const binanceData = await getBinanceData();

    // Extract base asset symbols (e.g., BTC from BTCUSDT)
    const symbols = binanceData.map((coin) => coin.symbol.replace('USDT', '').toLowerCase());
    // Fetch detailed coin data from CoinGecko
    const coinGeckoData = await getCoinGeckoData(symbols);

    // Map CoinGecko data to Binance data
    const mergedData = binanceData.map((binanceCoin) => {
      const baseAsset = binanceCoin.symbol.replace('USDT', '').toLowerCase();
      const coinGeckoCoin = coinGeckoData.find((coin) => coin.symbol === baseAsset);

      return {
        ...binanceCoin,
        name: coinGeckoCoin ? coinGeckoCoin.name : '',
        image: coinGeckoCoin ? coinGeckoCoin.image : '',
        market_cap: coinGeckoCoin ? coinGeckoCoin.market_cap : '',
        total_volume: coinGeckoCoin ? coinGeckoCoin.total_volume : '',
        current_price: binanceCoin.lastPrice, // Initialize with Binance data
        // Add other fields from CoinGecko as needed
      };
    });

    return mergedData;
  } catch (error) {
    console.error("ERROR>>>", error.message);
    return [];
  }
};
