import { LOGIN_SUCCESS, LOGOUT, BUY_COIN, SELL_COIN } from './actionTypes';

const initialState = {
    user: JSON.parse(localStorage.getItem("user")) || null,
};

function userReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: payload.user,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
            };
        case BUY_COIN:
            return {
                ...state,
                user: {
                    ...state.user,
                    balance: payload.balance,
                    cryptocurrencies: {
                        ...state.user.cryptocurrencies,
                        [payload.coin]: payload.coinBalance
                    }
                }
            };
        case SELL_COIN:
            return {
                ...state,
                user: {
                    ...state.user,
                    balance: payload.balance,
                    cryptocurrencies: {
                        ...state.user.cryptocurrencies,
                        [payload.coin]: payload.newCoinBalance
                    }
                }
            };
        default:
            return state;
    }
}

export default userReducer;
