import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@contexts/themeContext';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CoinsProvider } from '@contexts/CoinsContext';
import { UserDataProvider } from '@contexts/UserDataProvider';
import '@fonts/icomoon/icomoon.woff';
import { store, persistor } from "../src/store"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider>
                <CoinsProvider>
                    <UserDataProvider>
                <App/>
                </UserDataProvider>
                </CoinsProvider>
            </ThemeProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);
