import { LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS, BUY_COIN, SELL_COIN } from './actionTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { get200Coins } from '../functions/get100Coins';
export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: { user },
});

export const logoutSuccess = () => ({
    type: LOGOUT,
});

export const registerSuccess = () => ({
    type: REGISTER_SUCCESS,
});

export const loadUser = (user) => ({
    type: LOGIN_SUCCESS,
    payload: { user },
});

export const buyCoin = (balance,newCoinBalance, coin) => ({
    type: BUY_COIN,
    payload: { balance, newCoinBalance, coin },
});

export const sellCoin = (balance, newCoinBalance, coin, coinsSold) => ({
    type: SELL_COIN,
    payload: { balance, newCoinBalance, coin, coinsSold },
});

export const fetchCoins = createAsyncThunk('coins/fetchCoins', async () => {
  const coins = await get200Coins();
  return coins;
});

export const updateCoinPrices = (prices) => ({
  type: 'coins/updatePrices',
  payload: prices,
});