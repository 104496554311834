import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCoins } from './CoinsContext';
import io from 'socket.io-client';
import { AuthService } from '../services';
const UserDataContext = createContext();

export const useUserData = () => {
  return useContext(UserDataContext);
};

export const UserDataProvider = ({ children }) => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [allUser,setAllUser] = useState(JSON.parse(localStorage.getItem("allUsers")));

  const [portfolioArr, setPortfolioArr] = useState(user ? Object.keys(user.cryptocurrencies).filter(key => user.cryptocurrencies[key] !== 0)
  .map((key, index) => ({
    id: index,
    value: user.cryptocurrencies[key],
    label: key.slice(0, -4),
    amount:0,
    image:''
  })) : []);

  const [currentProfit, setCurrentProfit] = useState(0);
  const [userLeaderboardArr, setUserLeaderboardArr] = useState([]);
  const { coins } = useCoins();

   const [filteredData, setFilteredData] = useState(user ?  Object.keys(user.cryptocurrencies)
   .filter(key => user.cryptocurrencies[key] !== 0)
   .map((key, index) => ({
     id: index,
     value: user.cryptocurrencies[key],
     label: key.slice(0, -4),
     amount:0 ,
     image:''
   })) : [])
  

  useEffect(() => {

    const getLeaderboardData = async () => {
        const leaderboardData = allUser?.map(user => {
          const userBalance = user.balance;
          const userUpdatedValues = user.cryptocurrencies;
          const userFilteredData = Object.keys(userUpdatedValues)
            .filter(key => userUpdatedValues[key] !== 0)
            .map((key, index) => ({
              id: index,
              value: userUpdatedValues[key],
              label: key.slice(0, -4),
            }));
    
          let userTotalBalance = userBalance;
          userFilteredData.forEach(element => {
            const coin = coins.find(c => c.symbol.slice(0, -4).toLowerCase() === element.label.toLowerCase());
            const valueInUsd = coin ? element.value * coin.current_price : 0;
            userTotalBalance += valueInUsd;
          });
    
          const targetValue = 10000;
          const userCurrentProfit = ((userTotalBalance - targetValue) / targetValue) * 100;
    
          return {
            ...user,
            totalBalance: userTotalBalance,
            currentProfit: userCurrentProfit,
          };
        });
    
        leaderboardData?.sort((a, b) => b.currentProfit - a.currentProfit);
        setUserLeaderboardArr(leaderboardData);
    }
    const updateTotalBalance = async () => {
      if(filteredData){
        let arr = Object.keys(user.cryptocurrencies).filter(key => user.cryptocurrencies[key] !== 0)
        .map((key, index) => ({
          id: index,
          value: user.cryptocurrencies[key],
          label: key.slice(0, -4),
          amount:0,
          image:''
        }))
        let portfolioRefArr = [...arr]
        let totalBalanceRef = user.balance;
        filteredData.forEach((element,index) => {
          const coin = coins.find(c => c.symbol.slice(0, -4).toLowerCase() === element.label.toLowerCase());
          const valueInUsd = coin ? element.value * coin.current_price : 0;
          const coinImage = coin ? coin.image : "";
  
          portfolioRefArr[index].amount = valueInUsd
          portfolioRefArr[index].image = coinImage
  
          totalBalanceRef += valueInUsd;
        });
        setPortfolioArr(portfolioRefArr)
        setTotalBalance(totalBalanceRef);
        // Calculate the percentage difference from 10000
        const targetValue = 10000;
        const percentageChange = ((totalBalanceRef - targetValue) / targetValue) * 100;
  
        // Update the currentProfit based on the percentage change
        setCurrentProfit(percentageChange);
      }
    };
 
      allUser && getLeaderboardData()
      user && updateTotalBalance();

      const handleCustomEvent = () => {
        const userRef = JSON.parse(localStorage.getItem("user"));
        console.log(userRef)
        //const allUserRef = JSON.parse(localStorage.getItem("allUsers")) || [];
        setUser(userRef)
        setFilteredData(userRef ?  Object.keys(userRef.cryptocurrencies)
        .filter(key => userRef.cryptocurrencies[key] !== 0)
        .map((key, index) => ({
          id: index,
          value: userRef.cryptocurrencies[key],
          label: key.slice(0, -4),
          amount:0 ,
          image:''
        })) : null)

      };
      
      window.addEventListener('customStorageChange', handleCustomEvent);
      
      return () => {
        window.removeEventListener('customStorageChange', handleCustomEvent);
      };
    


  }, [coins,user,allUser,filteredData]);



  const value = {
    totalBalance,
    currentProfit,
    userLeaderboardArr,
    portfolioArr,
    setAllUser,
    setUser
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};


// Function to trigger custom storage change event
export const triggerCustomStorageChange = () => {
  const event = new Event('customStorageChange');
  window.dispatchEvent(event);
};