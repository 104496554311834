import React, { createContext, useContext, useState, useEffect } from 'react';
import { get200Coins } from '../functions/get100Coins';

const CoinsContext = createContext();

export const useCoins = () => {
  return useContext(CoinsContext);
};

export const CoinsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [coins, setCoins] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await get200Coins();
      if (data) {
        setCoins(data);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      const ws = new WebSocket(`wss://stream.binance.com:9443/ws/!miniTicker@arr`);

      ws.onopen = () => {
        console.log('WebSocket Connected');
      };

      ws.onmessage = (event) => {
        const messages = JSON.parse(event.data);
        setCoins(prevCoins => 
          prevCoins.map(coin => {
            const updatedCoin = messages.find(msg => msg.s.toLowerCase() === coin.symbol.toLowerCase());
            if (updatedCoin) {
              return { ...coin, current_price: parseFloat(updatedCoin.c) };
            }
            return coin;
          })
        );
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log('WebSocket Disconnected');
      };

      return () => {
        ws.close();
      };
    }
  }, [loading, coins.length]);

  const value = {
    loading,
    coins,
  };

  return (
    <CoinsContext.Provider value={value}>
      {children}
    </CoinsContext.Provider>
  );
};
